import { render, staticRenderFns } from "./teamMember.vue?vue&type=template&id=6aae8709&scoped=true&"
import script from "./teamMember.vue?vue&type=script&lang=js&"
export * from "./teamMember.vue?vue&type=script&lang=js&"
import style0 from "./teamMember.vue?vue&type=style&index=0&id=6aae8709&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aae8709",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6aae8709')) {
      api.createRecord('6aae8709', component.options)
    } else {
      api.reload('6aae8709', component.options)
    }
    module.hot.accept("./teamMember.vue?vue&type=template&id=6aae8709&scoped=true&", function () {
      api.rerender('6aae8709', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8Aa57Q9xC3aaM/JS/teamMember.vue"
export default component.exports